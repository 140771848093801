export const CONFIG = {
    title: 'Soluciones integrales a tu medida',
    name: 'Isaac Mayorga',
    email: 'i.mayorga@maleiservicios.com',
    phones: [
        '+52 81 1691 1351',
    ],
    address: null,
    social: {
        facebook: 'https://www.facebook.com/MALEI-Administración-De-Servicios-560553067789726/',
        instagram: 'https://instagram.com/malei_administracion?igshid=kmf2dp1ba5jk',
        twitter: null,
        phone: '+528116911351',
        linkedin: null,
        whatsapp: 'https://wa.me/528116911351?text=Hola, te contacto desde tu tarjeta de presentacion. ',
        webPage: 'https://bit.ly/2O9BQ9o'
    },
    logo: '/assets/logo.jpeg',
    colors: {
        background: '#FFFFFF',
        foreground: '#838587',
        foregroundTitle: '#cda63e'
    }
}